import React from 'react';

const FinishValidation = ({ res, id }) => {
    const censorName = (name) => {
        return name.split(' ').map(part => part[0] + '*'.repeat(part.length - 1)).join(' ');
    };

    const censorRut = (rut) => {
        return rut.replace(/^(\d{2})\.\d{3}\.\d{3}-(\d)$/, '$1.***.***-$2');
    };

    const data = {
        id: id,
        fechaEmision: new Date(res.order.date).toLocaleDateString(),
        tipoDocumento: 'Orden Médica',
        profesional: {
            nombre: 'Jorge Caro Díaz',
            rut: '15.946.308-7',
            especialidad: 'Médico-Cirujano',
            sucursal: 'On-Line'
        },
        paciente: {
            nombre: censorName(res.user.name),
            rut: censorRut(res.user.rut),
            edad: res.order.age,
            genero: res.user.gender
        },
        examenes: res.order.exams
    };
    return (
        <div className='container mt-5 text-start'>
            <div className='card'>
                <div className='card-header'>
                    <h1 className='card-title' style={{ color: '#6633ff' }}>Documento emitido por YoMeControlo</h1>
                    <h3 style={{ color: '#6633ff' }}>Detalle Documento</h3>
                </div>
                <div className='card-body'>
                    <div className='mb-1 d-flex flex-wrap'>
                        <p className='me-3 fs-5 my-0'><strong>Identificador:</strong> {data.id}</p>
                        <p className='me-3 fs-5 my-0'><strong>Fecha de emisión:</strong> {data.fechaEmision}</p>
                        <p className='me-3 fs-5 my-0'><strong>Tipo de Documento:</strong> {data.tipoDocumento}</p>
                    </div>
                    <div className='mb-1'>
                        <p className='fs-5 my-0'><strong>Profesional:</strong></p>
                        <p className='fs-5 ps-3 my-0'><strong>Nombre:</strong> {data.profesional.nombre}</p>
                        <p className='fs-5 ps-3 my-0'><strong>Rut:</strong> {data.profesional.rut}</p>
                        <p className='fs-5 ps-3 my-0'><strong>Especialidad:</strong> {data.profesional.especialidad}</p>
                        <p className='fs-5 ps-3 my-0'><strong>Sucursal:</strong> {data.profesional.sucursal}</p>
                    </div>
                    <div className='mb-1'>
                        <p className='fs-5 my-0'><strong>Paciente:</strong></p>
                        <p className='fs-5 ps-3 my-0'><strong>Nombre:</strong> {data.paciente.nombre}</p>
                        <p className='fs-5 ps-3 my-0'><strong>Rut:</strong> {data.paciente.rut}</p>
                        <p className='fs-5 ps-3 my-0'><strong>Edad:</strong> {data.paciente.edad}</p>
                        <p className='fs-5 ps-3 my-0'><strong>Género:</strong> {data.paciente.genero}</p>
                    </div>
                    <div className='mb-3'>
                        <p className='fs-5 my-0'><strong>Examenes:</strong></p>
                            <div className='row'>
                                    {data.examenes.map((examen, index) => (
                                        <div key={index} className='col-6'>
                                            <li className='fs-5 ps-3 my-0'>{examen}</li>
                                        </div>
                                    ))}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinishValidation;