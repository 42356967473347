import React from 'react';
import StartValidation from '../components/start_validation';
import FinishValidation from '../components/finish_validation';

const ValidationPage = () => {
    const [isValidated, setIsValidated] = React.useState(false);
    const [formData, setFormData] = React.useState(null);
    const [formId, setFormId] = React.useState(null);

    const handleValidationComplete = (data, id) => {
        setFormData(data);
        setFormId(id);
        setIsValidated(true);
    };



return (
        <div>
            {!isValidated ? (
                <StartValidation onComplete={handleValidationComplete} />
                ) : (
                <FinishValidation res={formData} id={formId} formData={formData} />
                )}
            <p className='mx-auto pt-1' style={{ textAlign: 'center' }}>* Esta página en ningún caso reemplaza la receta original, su uso es netamente para verificar validez del documento real que contiene firma del médico.</p>
        </div>
        );
};

export default ValidationPage;