import React, { useState, useEffect } from 'react';
import image from '../img/image.png';

const validarDocumento = async (codigo) => {
    let res;
    if (codigo === '8xs0eq-43pw7j-2j62l8') {
        res = {
            order: {
            age: '22',
            //exams: ['YMC095', 'YMC096', 'YMC097', 'YMC098', 'YMC099', 'YMC100', 'YMC101', 'YMC102', 'YMC103', 'YMC001', 'YMC002', 'YMC003', 'YMC004', 'YMC005', 'YMC006', 'YMC007', 'YMC008', 'YMC009', 'YMC026'],
            exams: ["IgE Total", "Panel IgE específico 22 alérgenos", "Panel IgE específico 36 alérgenos en sangre", "Test IgE MicroArray -Alex2- para 256 alérgenos"],
            date: '2024-09-22T21:05:28.787Z'
            },
            user: {
            name: 'Diego Eduardo Duhalde Venegas',
            rut: '21.133.269-7',
            email: 'dduhalde@hotmail.com',
            gender: 'M'
            }
        };
        } else if (codigo === '8xs0eq-43pw7j-2j62l9') {
        res = {
            order: {
            age: '22',
            //exams: ['YMC110', 'YMC111', 'YMC112'],
            exams: ["Test cutáneo 16 alérgenos", "Prick Test para inhalantes", "Prick Test para látex", "Prick Test para alimentos", "Prick Test para leches"],
            date: '2024-09-22:00:00.000Z'
            },
            user: {
            name: 'Diego Eduardo Duhalde Venegas',
            rut: '21.133.269-7',
            email: 'dduhalde@hotmail.com',
            gender: 'M'
            }
        };
        } else {
        res = {};
    }
    return res;
};

const StartValidation = ({ onComplete }) => {
    const [codigo, setCodigo] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Al montar el componente, precargar el valor del código si está en la URL
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const codigoFromUrl = searchParams.get('codigo');
        if (codigoFromUrl && /^[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}$/.test(codigoFromUrl)) {
            setCodigo(codigoFromUrl);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const regex = /^[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}$/;

        // Verifica el formato del código
        if (!regex.test(codigo)) {
            setErrorMessage('Formato del código inválido, favor verificar.');
            return;
        }

        // Consulta a la API
        const res = await validarDocumento(codigo);
        if (Object.keys(res).length > 0) {
            onComplete(res, codigo); // Pasa los datos al componente principal si es válido
        } else {
            setErrorMessage('No se ha podido encontrar el documento.');
        }
    };

    // Limpia el mensaje de error al cambiar el input
    const handleInputChange = (e) => {
        setCodigo(e.target.value);
        if (errorMessage) {
            setErrorMessage('');
        }
    };

    return (
        <div className="mt-3 d-flex flex-column align-items-center">
            <h1 style={{ color: '#6633ff' }}>Validación de Documentos YoMeControlo</h1>
            <div className="d-flex mt-3">
                <form className="me-3 my-auto pe-3 needs-validation" onSubmit={handleSubmit}>
                    <p>Validador de documentos emitidos por YoMeControlo.</p>
                    <div className="mb-3">
                        <input
                            type="text"
                            className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                            id="codigo_documento"
                            placeholder="Ingresa el código del documento"
                            value={codigo}
                            onChange={handleInputChange}
                            required
                        />
                        {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
                    </div>
                    <div className="d-grid gap-2">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ backgroundColor: '#6633ff', borderColor: '#6633ff' }}
                        >
                            Validar
                        </button>
                    </div>
                </form>
                <img className="ps-2 d-none d-md-block" src={image} alt="Descripción de la imagen" />
            </div>
        </div>
    );
};

export default StartValidation;
